body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: "Poppins-Regular";
    src: url("../fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-SemiBold";
    src: url("../fonts/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Barriecito-Regular";
    src: url("../fonts/Barriecito-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Britanica";
    src: url("../fonts/Britanica.ttf") format("truetype");
}

@font-face {
    font-family: "Doto-Regular";
    src: url("../fonts/Doto-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "OpenSans-Regular";
    src: url("../fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Almarai-Bold";
    src: url("../fonts/Almarai-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Almarai-Regular";
    src: url("../fonts/Almarai-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Bangers-Regular";
    src: url("../fonts/Bangers-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "BebasNeue-Regular";
    src: url("../fonts/BebasNeue-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Bitter-Bold";
    src: url("../fonts/Bitter-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Bitter-Regular";
    src: url("../fonts/Bitter-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Comfortaa-Bold";
    src: url("../fonts/Comfortaa-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Comfortaa-Regular";
    src: url("../fonts/Comfortaa-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "GloriaHallelujah-Regular";
    src: url("../fonts/GloriaHallelujah-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Itim-Regular";
    src: url("../fonts/Itim-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "JuliusSansOne-Regular";
    src: url("../fonts/JuliusSansOne-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Lora-Bold";
    src: url("../fonts/Lora-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Lora-Regular";
    src: url("../fonts/Lora-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Merriweather-Bold";
    src: url("../fonts/Merriweather-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Merriweather-Regular";
    src: url("../fonts/Merriweather-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat-Bold";
    src: url("../fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat-Regular";
    src: url("../fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "PinyonScript-Regular";
    src: url("../fonts/PinyonScript-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "PlayfairDisplay-Bold";
    src: url("../fonts/PlayfairDisplay-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "PlayfairDisplay-Regular";
    src: url("../fonts/PlayfairDisplay-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-Bold";
    src: url("../fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-Regular";
    src: url("../fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "VarelaRound-Regular";
    src: url("../fonts/VarelaRound-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "PlayfairDisplaySC-Bold";
    src: url("../fonts/PlayfairDisplaySC-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "PlayfairDisplaySC-Regular";
    src: url("../fonts/PlayfairDisplaySC-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-Medium";
    src: url("../fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Quantico-Bold";
    src: url("../fonts/Quantico-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Quantico-Regular";
    src: url("../fonts/Quantico-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Sevillana-Regular";
    src: url("../fonts/Sevillana-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "ShadowsIntoLight-Regular";
    src: url("../fonts/ShadowsIntoLight-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "ShipporiMincho-Bold";
    src: url("../fonts/ShipporiMincho-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "ShipporiMincho-Regular";
    src: url("../fonts/ShipporiMincho-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Silkscreen-Bold";
    src: url("../fonts/Silkscreen-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Silkscreen-Regular";
    src: url("../fonts/Silkscreen-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Sintony-Bold";
    src: url("../fonts/Sintony-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Sintony-Regular";
    src: url("../fonts/Sintony-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "SuezOne-Regular";
    src: url("../fonts/SuezOne-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Tangerine-Bold";
    src: url("../fonts/Tangerine-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Tangerine-Regular";
    src: url("../fonts/Tangerine-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Basic-Regular";
    src: url("../fonts/Basic-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Battambang-Bold";
    src: url("../fonts/Battambang-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Battambang-Regular";
    src: url("../fonts/Battambang-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Brygada1918-Bold";
    src: url("../fonts/Brygada1918-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Brygada1918-Regular";
    src: url("../fonts/Brygada1918-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Charmonman-Bold";
    src: url("../fonts/Charmonman-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Charmonman-Regular";
    src: url("../fonts/Charmonman-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "CormorantUnicase-Bold";
    src: url("../fonts/CormorantUnicase-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "CormorantUnicase-Regular";
    src: url("../fonts/CormorantUnicase-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "CraftyGirls-Regular";
    src: url("../fonts/CraftyGirls-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Crushed-Regular";
    src: url("../fonts/Crushed-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "GiveYouGlory-Regular";
    src: url("../fonts/GiveYouGlory-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "HindGuntur-Bold";
    src: url("../fonts/HindGuntur-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "HindGuntur-Regular";
    src: url("../fonts/HindGuntur-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "KaiseiTokumin-Bold";
    src: url("../fonts/KaiseiTokumin-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "KaiseiTokumin-Regular";
    src: url("../fonts/KaiseiTokumin-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Kreon-Bold";
    src: url("../fonts/Kreon-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Kreon-Regular";
    src: url("../fonts/Kreon-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Michroma-Regular";
    src: url("../fonts/Michroma-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "PathwayExtreme-Bold";
    src: url("../fonts/PathwayExtreme-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "PathwayExtreme-Regular";
    src: url("../fonts/PathwayExtreme-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "PetitFormalScript-Regular";
    src: url("../fonts/PetitFormalScript-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "PlaywriteAUQLD-Regular";
    src: url("../fonts/PlaywriteAUQLD-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "PlaywriteNO-Regular";
    src: url("../fonts/PlaywriteNO-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "PlaywriteVN-Regular";
    src: url("../fonts/PlaywriteVN-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "ProzaLibre-Bold";
    src: url("../fonts/ProzaLibre-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "ProzaLibre-Regular";
    src: url("../fonts/ProzaLibre-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Share-Bold";
    src: url("../fonts/Share-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Share-Regular";
    src: url("../fonts/Share-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "SourGummy-Bold";
    src: url("../fonts/SourGummy-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "SourGummy-Regular";
    src: url("../fonts/SourGummy-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "StardosStencil-Bold";
    src: url("../fonts/StardosStencil-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "StardosStencil-Regular";
    src: url("../fonts/StardosStencil-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "SueEllenFrancisco-Regular";
    src: url("../fonts/SueEllenFrancisco-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "TiltNeon-Regular";
    src: url("../fonts/TiltNeon-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Tomorrow-Bold";
    src: url("../fonts/Tomorrow-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Tomorrow-Regular";
    src: url("../fonts/Tomorrow-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Trocchi-Regular";
    src: url("../fonts/Trocchi-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "TurretRoad-Bold";
    src: url("../fonts/TurretRoad-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "TurretRoad-Regular";
    src: url("../fonts/TurretRoad-Regular.ttf") format("truetype");
}